/**!
 * Scrollbar Compensation
 *
 * @author Aaron Waldon <aaron@causingeffect.com>
 * @copyright Aaron Waldon 2018
 */

/**
 * Gets the scrollbar width.
 *
 * @return int;
 */
const getScrollbarWidth = () => {
	//create the temp element
	let el = document.createElement('div');

	//style the temp element
	el.style.width = '50px';
	el.style.height = '50px';
	el.style.overflow = 'scroll';
	el.style.position = 'absolute';
	el.style.left = '-100px';

	//append the element
	document.body.appendChild(el);

	//get the width
	let scrollbarWidth = el.offsetWidth - el.clientWidth;

	//remove the element
	document.body.removeChild(el);

	//return the width
	return scrollbarWidth;
};

/**
 *
 * @param {function} styleCallback - Callback
 */
export default function(styleCallback = function(scrollbarWidth){}) {
	let scrollbarWidth = getScrollbarWidth();

	let styles = '';

	//get the user styles
	if(typeof styleCallback === 'function') {
		styles = styleCallback(scrollbarWidth);
	}

	if (!!styles) {
		let styleTag = document.createElement('style');
		styleTag.type = 'text/css';
		styleTag.innerHTML = styles;
		document.getElementsByTagName('head')[0].appendChild(styleTag);
	}
}