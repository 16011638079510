import * as basicScroll from './vendor/basicScroll';

document.querySelectorAll('[data-parallax-scene]').forEach(scene => {
	let varName = scene.getAttribute('data-parallax-scene') || 'delta';

	let options = {
		elem: scene,
		from: 'middle-bottom',
		to: 'middle-top',
		props: {
			['--'+varName]: {
				from: -100,
				to: 100
			}
		},
		direct: true
	};

	basicScroll.create(options).start()
});