import closest from 'closest';
import 'classlist.js';

// ---------- auto submit forms on select change ----------
let selectEls = document.querySelectorAll('select[data-submit-on-change]');

if (selectEls.length) {
	selectEls.forEach(selectEl => {
		selectEl.addEventListener('change', ()=>{
			let parentForm = closest(selectEl, 'form', false);
			if (parentForm) {
				parentForm.submit();
			}
		});
	});
}

// ---------- sync country and state selects ----------
let countryStateGroupEl = document.querySelectorAll('[data-country-and-state]');

if (countryStateGroupEl) {
	countryStateGroupEl.forEach(groupEl => {
		const countrySelEl = groupEl.querySelector('select[data-country]');
		const stateSelEl = groupEl.querySelector('select[data-state]');
		const stateInEl = groupEl.querySelector('input[data-state-input]');
		if (countrySelEl && stateSelEl && stateInEl) {
			//we've got 'em both

			/**
			 * Hides the state select and shows the state input.
			 */
			const hideSelect = () => {
				//show the input
				stateInEl.classList.remove('hidden');

				//add the input name
				stateInEl.setAttribute('name', stateInEl.getAttribute('data-name'));

				//hide the select
				stateSelEl.classList.add('hidden');

				//remove the input name
				stateSelEl.removeAttribute('name');
			};

			/**
			 * Shows the state select and hides the state input.
			 */
			const showSelect = () => {
				//show the select
				stateSelEl.classList.remove('hidden');

				//add the input name
				stateSelEl.setAttribute('name', stateSelEl.getAttribute('data-name'));

				//hide the input
				stateInEl.classList.add('hidden');

				//remove the input name
				stateInEl.removeAttribute('name');
			};

			/**
			 * Updates the state values depending on the country values.
			 */
			const updateStates = () => {
				//get the selected country
				let countryId = countrySelEl.value;

				if (countryId) { //we have a matching country

					//disable all of the optgroups
					let stateOptGroups = stateSelEl.querySelectorAll('optgroup');
					if (stateOptGroups.length) {
						stateOptGroups.forEach(opt => {
							opt.disabled = true;
						})
					}

					//find the matching optgroup
					let countryOptGroup = stateSelEl.querySelector('optgroup[data-country-id="'+countryId+'"]');
					if (countryOptGroup) { //we have a match
						//enable the state select
						stateSelEl.disabled = false;

						//enable this opt group
						countryOptGroup.disabled = false;

						//get the state value
						let stateVal = stateSelEl.value;

						//if a state is not selected or the selected state is not in this opt group
						if (! stateVal || !countryOptGroup.querySelector('option[value="'+stateVal+'"]')) {
							//set the value to the first option in the opt group
							let firstOption = countryOptGroup.querySelector('option');
							if (firstOption) {
								stateSelEl.value = firstOption.value;
							}
						}

						showSelect();
					} else { //no match
						hideSelect();
					}
				} else { //no country id
					showSelect();

					//disable the select
					stateSelEl.disabled = true;
				}
			};

			countrySelEl.addEventListener('change', updateStates);
			updateStates();
		}
	})
}