import SmoothScroll from 'smooth-scroll';
/**!
 * Smooth Scroll
 *
 * Sets up automatic in-page smooth scroll targets
 * and specified data targets.
 *
 * @author Aaron Waldon <aaron@causingeffect.com>
 * @copyright Aaron Waldon 2018
 */
export default {
	/**
	 * Sets up auto scroll and data targets at the same time.
	 *
	 * @param {int} speed The scroll speed.
	 * @param {int} offset The scroll offset.
	 */
	init(speed = 300, offset = 0) {
		this.setUpAutoScroll(speed, offset);
		this.setUpDataTargets(speed, offset);
	},
	/**
	 * Sets up auto scroll.
	 *
	 * Add the `data-scroll-ignore` attribute to links that you want to ignore.
	 *
	 * @param {int} speed The scroll speed.
	 * @param {int} offset The scroll offset.
	 */
	setUpAutoScroll(speed = 300, offset = 0) {
		const currentPage = window.location.protocol + '//' + window.location.hostname + window.location.pathname;
		const currentPageRelative = window.location.pathname;
		new SmoothScroll('a[href^="#"]:not([data-scroll-ignore]):not([href="#"]), a[href^="' + currentPage + '#"]:not([data-scroll-ignore]):not([href="#"]), [href^="' + currentPageRelative + '#"]:not([data-scroll-ignore])', {
			speed: speed,
			offset: offset
		});
	},
	/**
	 * Sets up data targets. Simply add:
	 * data-scroll-to="{selector}" where {selector} is a valid selector, like "#blah"
	 *
	 * The following optional data attributes can also be used:
	 * data-scroll-speed="300"
	 * data-scroll-offset="0"
	 * data-scroll-ignore - Ignore this link.
	 *
	 *
	 * @param {int} defaultSpeed The default speed, can be overridden per target.
	 * @param {int} defaultOffset The default offset, can be overridden per target.
	 */
	setUpDataTargets(defaultSpeed = 300, defaultOffset = 0) {
		let els = document.querySelectorAll('[data-scroll-to]:not([data-scroll-ignore])');
		if (els.length) {
			els.forEach(el => {
				el.addEventListener('click', e => {
					e.preventDefault();

					let targetId = el.getAttribute('data-scroll-to');
					let speed = el.getAttribute('data-scroll-speed') || defaultSpeed;
					let offset = el.getAttribute('data-scroll-offset') || defaultOffset;
					if (!!targetId) {

						new SmoothScroll(targetId, {
							speed: speed,
							offset: offset
						});
						scrollToTarget(targetId, speed, offset);
					}
				});
			})
		}
	}
};