// ---------- Tiny Slider ----------
import {tns} from 'tiny-slider/src/tiny-slider.module';

let sliders = document.querySelectorAll('[data-slider]');
sliders.forEach(slider => {
	let slides = slider.querySelectorAll('.slide');
	if (slides.length < 2) {
		return;
	}

	let sliderOptions = {
		container: slider,
		mode: 'carousel'
	};

	let sliderType = slider.getAttribute('data-slider');
	if (sliderType === 'banner') {
		sliderOptions = {
			container: slider,
			mode: 'gallery',
			controls: false,
			nav: false,
			autoplay: true,
			autoplayButton: false,
			autoplayButtonOutput: false,
			speed: 2000,
			autoplayTimeout: 5000,
			animateIn: 'slide--fadeIn',
			animateOut: 'slide--fadeOut',
			animateNormal: 'slide--fadeNormal'
		};
	}

	tns(sliderOptions);
});