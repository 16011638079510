import 'headjs/dist/1.0.0/head.load';

/**!
 * Map
 *
 * @author Aaron Waldon <aaron@causingeffect.com>
 * @copyright Aaron Waldon 2018
 */

/**
 * Dynamically loads Google Maps and sets up a
 * custom map.
 *
 * @param selector
 */
export default function (selector = '[data-map]') {
	let mapEls = document.querySelectorAll(selector);
	if (mapEls.length) {
		let key = mapEls[0].getAttribute('data-key');

		window.initGMaps = function() {
			mapEls.forEach(function(mapEl){
				let lat = parseFloat(mapEl.getAttribute('data-lat'));
				let lng = parseFloat(mapEl.getAttribute('data-lng'));
				let zoom = parseInt(mapEl.getAttribute('data-zoom'), 10);
				let markerName = mapEl.getAttribute('data-marker-name');
				let markerIcon = mapEl.getAttribute('data-marker-icon');
				let markerWidth = mapEl.getAttribute('data-marker-icon-width');
				let markerHeight = mapEl.getAttribute('data-marker-icon-height');
				let styleVar = mapEl.getAttribute('data-styles-var');
				let latLng = {lat:lat, lng:lng};
				let mapOptions = {
					center: latLng,
					zoom: zoom,
					scrollwheel: false,
					disableDoubleClickZoom: true,
					panControl: false,
					streetViewControl: false,
					mapTypeControl: false,
					fullscreenControl: false,
					zoomControl: false
				};

				//add the styles if applicable
				if (styleVar && window[styleVar]) {
					mapOptions.styles = window[styleVar];
				}

				let map = new google.maps.Map(mapEl, mapOptions);

				//marker options
				let markerOptions = {position:latLng, map:map};
				if (markerIcon) {
					markerOptions.icon = {
						url: markerIcon,
						size: new google.maps.Size(markerWidth, markerHeight),
						origin: new google.maps.Point(0, 0),
						anchor: new google.maps.Point(markerWidth*.5, markerHeight),
						scaledSize: new google.maps.Size(markerWidth, markerHeight)
					};
				}
				if (markerName) {
					markerOptions.place = {
						location: latLng,
						query: markerName
					};
				}

				let marker = new google.maps.Marker(markerOptions);
				if (markerName) {
					let infoWindow = new google.maps.InfoWindow({content:'<h1>'+markerName+'</h1>'});
					marker.addListener('click', function() {
						infoWindow.open(map, marker);
					});
					//new google.maps.event.trigger(marker, 'click');
				}

				// listen for the window resize event & trigger Google Maps to update too
				window.onresize = function() {
					let currentCenter = map.getCenter();
					google.maps.event.trigger(map, 'resize');
					map.setCenter(currentCenter);
				};
			});
		};

		head.load({ gMaps: 'https://maps.googleapis.com/maps/api/js?key='+key+'&callback=initGMaps' });
	}
}