import 'classlist.js';

/**
 * Detect the animation event
 *
 * @see https://jonsuh.com/blog/detect-the-end-of-css-animations-and-transitions-with-javascript/
 * @return {*}
 */
function whichTransitionEvent(){
	let t,
		el = document.createElement("fakeelement");

	const transitions = {
		"transition"      : "transitionend",
		"OTransition"     : "oTransitionEnd",
		"MozTransition"   : "transitionend",
		"WebkitTransition": "webkitTransitionEnd"
	};

	for (t in transitions){
		if (el.style[t] !== undefined){
			return transitions[t];
		}
	}
}

/**!
 * Notify
 *
 * @author Aaron Waldon <aaron@causingeffect.com>
 * @copyright Aaron Waldon 2018
 */

let defaultDisplayTime = 0;
let defaultIsDismissible = true;
let wrapper = null;
let mainClass = '';
let mainInnerClass = '';
let closingClass = '';
let openingTransitionClass = '';
let closingTransitionClass = '';
let dismissClass = '';
let isSetUp = false;
const transitionEvent  = whichTransitionEvent();

export default {
	/**
	 * Initializes the notifications.
	 *
	 * @param wrapperClass
	 * @param baseClass
	 * @param baseInnerClass
	 * @param closeClass
	 * @param closeTransitionClass
	 * @param openTransitionClass
	 * @param time
	 * @param dismissibleClass
	 * @param isDismissible
	 */
	init({
		     wrapperClass = 'notify-wrapper',
		     baseClass = 'notify',
		     baseInnerClass = 'notify-inner',
		     closeClass = 'notify-close',
		     closeTransitionClass = 'notify--close',
		     openTransitionClass = 'notify--open',
		     time = 0,
		     dismissibleClass = 'notify--dismissible',
		     isDismissible = true
	     } = {}) {
		isSetUp = true;
		defaultDisplayTime = time;
		defaultIsDismissible = isDismissible;
		mainClass = baseClass;
		mainInnerClass = baseInnerClass;
		closingClass = closeClass;
		closingTransitionClass = closeTransitionClass;
		openingTransitionClass = openTransitionClass;
		dismissClass = dismissibleClass;

		//set up the wrapper
		wrapper = document.createElement('div');
		wrapper.classList.add(wrapperClass);
		document.body.appendChild(wrapper);
	},
	/**
	 * Shows a notification.
	 *
	 * @param text
	 * @param messageType
	 * @param displayTime
	 * @param isDismissible
	 */
	show(
		text = '',
		messageType = '' ,
		{
			displayTime = defaultDisplayTime,
			isDismissible = defaultIsDismissible
		} = {}) {
		if (!isSetUp) {
			console.error('Notify needs to init before showing notifications');
			return;
		}

		//set up the notification
		let notification = document.createElement('div');
		notification.classList.add(mainClass);

		//set up the inner div
		let inner = document.createElement('div');
		inner.classList.add(mainInnerClass);
		inner.setAttribute('role', 'alert');
		if (messageType) {
			inner.classList.add(mainClass+'--'+messageType);
		}
		notification.appendChild(inner);

		//set the text
		inner.innerHTML = text;

		//optional close button
		let closer = null;
		if (isDismissible) {
			inner.classList.add(dismissClass);

			closer = document.createElement('button');
			closer.classList.add(closingClass);
			closer.setAttribute('aria-label', 'Close');
			inner.appendChild(closer);

			let clickHandler = e => {
				e.preventDefault();
				this.hide(notification);

				closer.removeEventListener('click', clickHandler);
			};

			closer.addEventListener('click', clickHandler);
		}

		//add notification
		wrapper.appendChild(notification);
		window.setTimeout(()=>{
			notification.classList.add(openingTransitionClass);
		}, 100);

		//optional display timeout
		if (displayTime) {
			window.setTimeout(function () {
				this.hide(notification);
			}, displayTime);
		}
	},
	/**
	 * Hides a notification.
	 *
	 * @param notificationEl
	 */
	hide(notificationEl) {
		//add the closeClass
		notificationEl.classList.add(closingTransitionClass);

		let afterTransition = () => {
			notificationEl.removeEventListener(transitionEvent, afterTransition);
			notificationEl.innerHTML = '';
			wrapper.removeChild(notificationEl);
		};

		//delete after the transition ends
		notificationEl.addEventListener(transitionEvent, afterTransition);
	}
}