import 'classlist.js';

/**!
 * JS Class
 *
 * @author Aaron Waldon <aaron@causingeffect.com>
 * @copyright Aaron Waldon 2018
 */
export default function () {
    let html = document.querySelector('html');
    html.classList.remove('no-js');
    html.classList.add('js');
}