import 'classlist.js';
import SmoothScroll from 'smooth-scroll';
import debounce from 'debounce';

/**
 * Create an element from HTML.
 *
 * @see https://stackoverflow.com/a/494348/1136822
 * @param {string} htmlString
 * @return {node|null}
 */
const createElementFromHTML = function(htmlString) {
	let div = document.createElement('div');
	div.innerHTML = htmlString.trim();

	// Change this to div.childNodes to support multiple top-level nodes
	return div.firstChild;
};

/**!
 * Down link
 *
 * @author Aaron Waldon <aaron@causingeffect.com>
 * @copyright Aaron Waldon 2018
 */
export default {
	/**
	 * Creates the dropdown link element and initializes it.
	 *
	 * @param {string} htmlString
	 * @param {object} options
	 */
	initFromHtml(htmlString, options = {}) {
		let el = createElementFromHTML(htmlString);
		document.body.appendChild(el);
		this.init(el, options);
	},
	/**
	 * Initializes the dropdown link from an element.
	 *
	 * @param {node} el
	 * @param {string} baseClass
	 * @param {string} inClass
	 * @param {string} outClass
	 * @param {int} speed
	 * @param {int} offset
	 */
	init(el, {
			baseClass = 'animated',
			inClass = 'fadeIn',
			outClass = 'fadeOut',
			speed = 500,
			offset = 0
		} = {}) {

		//add the base class
		if (!!baseClass) {
			el.classList.add(baseClass);
		}

		let alreadyTriggered = false;
		let tolerance = 250;

		/**
		 * Updates the classes on the element.
		 */
		const updateClasses = function() {
			let scrollTop  = window.pageYOffset || document.documentElement.scrollTop;
			let pageHeight = document.documentElement.scrollHeight;
			let windowHeight = window.innerHeight|| document.documentElement.clientHeight || document.body.clientHeight;

			if ( scrollTop > 0 || pageHeight - tolerance <= windowHeight ) {
				el.classList.remove(inClass);
				el.classList.add(outClass);
				alreadyTriggered = true;
				window.removeEventListener('scroll', debouncedUpdate);
			} else {
				if (!alreadyTriggered) {
					el.classList.remove(outClass);
					el.classList.add(inClass);
				}
			}
		};

		//debounced version, because of performance and stuff
		const debouncedUpdate = debounce(updateClasses, 10, false);

		//listen for scroll
		window.addEventListener('scroll', debouncedUpdate);

		//set up smooth scroll
		let scroller = new SmoothScroll();

		//listen for click
		el.addEventListener('click', e => {
			e.preventDefault();
			let topPos = el.getBoundingClientRect().top + window.scrollY;
			scroller.animateScroll(topPos, null, {
				speed: speed,
				offset: offset
			});
		});

		//call immediately
		updateClasses();

		//call a little later
		setTimeout(updateClasses, 500);
	}
}