import 'matches-selector-polyfill/dist/matches-selector-polyfill';
import 'classlist.js';

/**
 * Polyfill for previousElementSibling.
 *
 * @see https://stackoverflow.com/a/5197873/1136822
 *
 * @param el
 * @return {*}
 */
const previousElementSibling = function( el ) {
	if( el.previousElementSibling ) {
		return el.previousElementSibling;
	} else {
		while( el = el.previousSibling ) {
			if( el.nodeType === 1 ) return el;
		}
	}
};

/**!
 * Collapse Backgrounds
 *
 * @author Aaron Waldon <aaron@causingeffect.com>
 * @copyright Aaron Waldon 2018
 */

/**
 * Adds a class to consecutive blocks that have
 * custom backgrounds of the same background color.
 *
 * @param {string} [selector='.bg--custom']
 * @param {string} [collapsedClass='bg--customCollapsed']
 */
export default function (selector = '.bg--custom + .bg--custom', collapsedClass = 'bg--customCollapsed') {
	if (!collapsedClass) {
		return;
	}

	//get the source selector
	let els = document.querySelectorAll(selector);
	els.forEach(el => {
		let prevEl = previousElementSibling(el);
		if (prevEl && prevEl.style.backgroundColor === el.style.backgroundColor) {
			el.classList.add(collapsedClass);
		}
	});
}