import axios from 'axios';

/**!
 * Image to SVG
 *
 * @author Aaron Waldon <aaron@causingeffect.com>
 * @copyright Aaron Waldon 2018
 */
export default function (selector = 'img[src$=".svg"]', attrsToIgnore = ['src']) {
	let images = document.querySelectorAll(selector);
	images.forEach(img => {
		//send the request
		axios({
			url: img.getAttribute('src'),
			method: 'GET',
			responseType: 'text'
		})
			.then(response => {
				let div = document.createElement('div');

				div.innerHTML = response.data;

				//get the svg
				let svg = div.querySelector('svg');

				if (svg) {
					//copy the attributes from the image to the svg
					Array.from(img.attributes).forEach(attr => {
						if (attrsToIgnore.indexOf(attr.name) < 0) {
							svg.setAttribute(attr.name, attr.value);
						}
					});

					//remove invalid xml tags
					svg.removeAttribute('xmlns:a');

					//try to set a viewbox so the svg will scale
					if(!svg.getAttribute('viewBox') && svg.getAttribute('height') && svg.getAttribute('width')) {
						svg.setAttribute('viewBox', '0 0 ' + svg.getAttribute('height') + ' ' + svg.getAttribute('width'))
					}

					//replace img with new svg
					img.parentNode.replaceChild(svg, img);
				}
			})
			.catch(error => {
				console.log(error);
			});
	});
}