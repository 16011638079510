import 'classlist.js';
import jsonp from 'jsonp';

/**!
 * MailChimp Subscribe Form
 *
 * @author Aaron Waldon <aaron@causingeffect.com>
 * @copyright Aaron Waldon 2018
 */
export default function ({
	                         selector = '[data-ajax-subscribe]',
	                         notificationSelector = '.subscribeForm-message',
	                         sendingClass = 'subscribeForm--sending',
	                         successClass = 'subscribeForm--success',
	                         sendingMessage = '<p class="subscribeForm-sendingMessage">Sending&hellip;</p>'
                         } = {}) {
	let formEls = document.querySelectorAll(selector);
	if (formEls.length) {
		formEls.forEach(formEl => {
			let notificationContainer = formEl.querySelector(notificationSelector);
			let isSending = false;

			let actionUrl = formEl.getAttribute('action');
			actionUrl = actionUrl.replace('post?', 'post-json?');

			formEl.addEventListener('submit', e => {
				e.preventDefault();

				//attempt to prevent duplicates
				if (isSending) {
					return false;
				}
				isSending = true;

				//add the sending class
				formEl.classList.add(sendingClass);

				//create sending notification
				if (notificationContainer) {
					notificationContainer.innerHTML = sendingMessage;
				}

				//add form pairs to URL
				let formData = new FormData(formEl);
				let formPairs = [];
				for(let pair of formData.entries()) {
					formPairs.push(encodeURIComponent(pair[0])+'='+encodeURIComponent(pair[1]));
				}
				let paramUrl = actionUrl;
				if (formPairs.length) {
					paramUrl = paramUrl+'&'+formPairs.join('&');
				}

				//send the request
				jsonp(paramUrl, {param: 'c'}, (err, data) => {

					//mark as not sending
					isSending = false;

					//remove the sending class
					formEl.classList.remove(sendingClass);

					//empty the notifications
					if (notificationContainer) {
						notificationContainer.innerHTML = '';
					}

					if (err) {
						alert(error);
					} else {
						if (data.result !== 'success') {
							if (data.hasOwnProperty('msg') && data.msg.indexOf('already subscribed') > -1) {
								//add the success class
								formEl.classList.add(successClass);

								//show the success message
								if (notificationContainer) {
									notificationContainer.innerHTML = data.msg;
								}
							} else {
								let message = data.msg || 'Sorry. Unable to subscribe. Please try again later.';
								message = message.replace(/^\d+ - /, '');
								alert(message);
							}
						} else {
							//add the success class
							formEl.classList.add(successClass);

							//show the success message
							if (notificationContainer) {
								notificationContainer.innerHTML = data.msg;
							}
						}
					}
				});

			});
		})
	}
}