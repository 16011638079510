import 'classlist.js';
import debounce from 'debounce';

/**
 * Video Cover
 *
 * Responsive background video fill.
 *
 * @author Aaron Waldon <aaron@causingeffect.com>
 * @copyright Aaron Waldon 2018
 * @param selector
 */
export default function(selector = '[data-video-cover-holder]') {
	let holderEls = document.querySelectorAll(selector);
	holderEls.forEach(holderEl => {
		let videoEl = holderEl.querySelector('video');
		let videoRatio = holderEl.getAttribute('data-width')*1 / holderEl.getAttribute('data-height');

		videoEl.style.height = 'auto';
		videoEl.style.minHeight = 'auto';

		const resizeVideo = () => {
			//adjust the video width if necessary
			videoEl.style.width = (holderEl.offsetHeight*videoRatio)+'px';
		};

		//watch
		['DOMContentLoaded', 'load', 'resize'].forEach(function(eventName) {
			window.addEventListener(eventName, debounce(resizeVideo, 10, false));
			resizeVideo();
		});
	});
}