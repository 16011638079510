// ---------- Config Setup ----------
const SITE = window.SITE || {};
//const isDevMode = SITE.devMode || false;
//const isProduction = SITE.isProduction || false;
global.isAdminLoggedIn = SITE.isAdminLoggedIn || false;
global.mobileMenuMaxWidth = SITE.mobileMenuMaxWidth || 0;

// ---------- Variables ----------
const scrollSpeed = 1000;

// ---------- Polyfills ----------
import 'nodelist-foreach-polyfill';
import 'requestAnimationFrame';
import 'svgxuse';
import 'string.prototype.startswith';

import setAsap from 'setasap'; //needed for promise
import Promise from 'promise-polyfill/src/polyfill'; //needed for IE and axios

// ---------- Components ----------
import jsClass from './Components/jsClass';
jsClass();

import SmoothScrollLinks from './Components/smoothScroll';
SmoothScrollLinks.init(scrollSpeed, 0);

import downLink from './Components/downLink';
downLink.initFromHtml('<div class="scrollLink-wrapper"><button class="scrollLink"><svg class="icon icon-arrow-down"><use xlink:href="/dist/svg/icons.svg#arrow-down"></use></svg></button></div>', {speed:scrollSpeed});

let hasSearch = document.querySelector('script[data-search-app]');
if (hasSearch) {
	import(/* webpackChunkName: "search" */ './Components/search/search');
}

import topLink from './Components/topLink';
topLink.initFromHtml('<div class="topLink-wrapper"><button class="topLink"><svg class="icon icon-arrow-up"><use xlink:href="/dist/svg/icons.svg#arrow-up"></use></svg></button></div>', {speed:scrollSpeed, min:400});

import resposiveVideos from './Components/responsiveVideos';
resposiveVideos.init();

import videoCover from './Components/videoCover';
videoCover();

import scrollbarCompensation from './Components/scrollbarCompensation';
scrollbarCompensation(scrollbarWidth => {
	return '.overflowHidden .header, .overflowHidden body {padding-right: '+scrollbarWidth+'px;} '
		+ '.modal-shade {right: '+scrollbarWidth+'px;}';
});

//import craftAutoCSRF from './Components/craftAutoCsrf';
//craftAutoCSRF();

import craftAjaxForm from './Components/craftAjaxForm';
craftAjaxForm();

import simpleMap from './Components/simpleMaps';
simpleMap();

import collapseBackgrounds from './Components/collapseBackgrounds';
collapseBackgrounds();

import notify from './Components/notify';
notify.init();
let messages = document.querySelectorAll('[data-notify]');
messages.forEach((message, index) => {
	window.setTimeout(() => {
		notify.show(message.innerHTML, message.getAttribute('data-notify'))
	}, 500*index);
});

import mobileMenu from './Components/mobileMenu';
mobileMenu.init({maxWidth: mobileMenuMaxWidth});

import './Components/commerce';

import './Components/sliders';

import drawers from './Components/drawers';
drawers.init();

import imgToSvg from './Components/imgToSvg';
imgToSvg();

import subscribeForm from './Components/mcSubscribeForm';
subscribeForm();

// ---------- Lazysizes ----------
import lazySizes from 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange'
import 'lazysizes/plugins/bgset/ls.bgset'
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import 'lazysizes/plugins/respimg/ls.respimg';
Object.assign(lazySizes.cfg, {loadMode:1});

// ---------- Lightbox ----------
//import 'wa-mediabox/src/wa-mediabox';

// ---------- basicScroll ----------
import './Components/parallax';