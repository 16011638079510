import 'classlist.js';
import axios from 'axios';

/**!
 * Craft AJAX Form
 *
 * @author Aaron Waldon <aaron@causingeffect.com>
 * @copyright Aaron Waldon 2018
 */
export default function ({
	                         selector = '[data-ajax-form]',
	                         notificationSelector = '.ajaxForm-notifications',
	                         sendingClass = 'ajaxForm--sending',
	                         successClass = 'ajaxForm--success',
	                         sendingMessage = '<p class="ajaxForm-sendingMessage">Sending&hellip;</p>',
	                         successMessage = 'The form was sent successfully!',
	                         successMessageClass = 'ajaxForm-successMessage',
	                         errorMessage = 'There was a problem sending the form.',
	                         errorMessageClass = 'ajaxForm-errorMessage',
	                         errorListClass = 'ajaxForm-errors',
	                         inlineErrorClass = 'ajaxForm-errorInline',
                         } = {}) {
	let formEls = document.querySelectorAll(selector);
	if (formEls.length) {
		formEls.forEach(formEl => {
			let notificationContainer = formEl.querySelector(notificationSelector);
			let isSending = false;

			formEl.addEventListener('submit', e => {
				e.preventDefault();

				//attempt to prevent duplicates
				if (isSending) {
					return false;
				}
				isSending = true;

				//get the redirect
				let redirectInput = formEl.querySelector('[name="redirect"]');
				let redirectUrl = redirectInput ? redirectInput.getAttribute('value') : '';

				//get the action
				let actionInput = formEl.querySelector('[name="action"]');
				let actionUrl = actionInput ? '/actions/'+actionInput.getAttribute('value'): '/';

				//add the sending class
				formEl.classList.add(sendingClass);

				//create sending notification
				if (notificationContainer) {
					notificationContainer.innerHTML = sendingMessage;
				}

				//send the request
				axios({
					url: actionUrl,
					method: 'POST',
					headers: {'X-Requested-With': 'XMLHttpRequest'},
					data: new FormData(formEl)
				})
					.then(response => {

						let responseData = response.data;

						//mark as not sending
						isSending = false;

						//remove the sending class
						formEl.classList.remove(sendingClass);

						//empty the notifications
						if (notificationContainer) {
							notificationContainer.innerHTML = '';
						}

						//remove the errors
						let errorEls = formEl.querySelectorAll(inlineErrorClass);
						if (errorEls.length) {
							errorEls.remove();
						}

						if (responseData.hasOwnProperty('success') && responseData.success) {
							if (redirectUrl) {
								//redirect
								return window.location.href = redirectUrl;
							} else {
								//add the success class
								formEl.classList.add(successClass);

								//show the success message
								if (notificationContainer) {
									notificationContainer.innerHTML = `<p class="${successMessageClass}">${successMessage}</p>`;
								}

								//reset the form
								return formEl.reset();
							}
						} else {
							//set the error message
							if (notificationContainer) {
								notificationContainer.innerHTML = `<p class="${errorMessageClass}">${errorMessage}</p>`;
							}

							if (responseData.hasOwnProperty('error')) {
								//the error container element
								let errorsContainerEl = document.createElement('ul');
								errorsContainerEl.classList.add(errorListClass);
								if (notificationContainer) {
									notificationContainer.appendChild(errorsContainerEl);
								}

								//add the inline error messages
								Object.keys(responseData.error).forEach(fieldName => {
									//get the first error
									let validationError = responseData.error[fieldName][0];

									//get the field
									let field = formEl.querySelector('[name="'+fieldName+'"]');

									//get the field's id
									let id = field ? field.getAttribute('id') : false;

									//find the label by id
									let label = id ? formEl.querySelector('label[for="'+id+'"]') : false;

									if (label) { //if there is a label, add an inline error
										let inlineError = document.createElement('span');
										inlineError.classList.add(inlineErrorClass);
										inlineError.innerHTML = validationError;
										label.parentNode.insertBefore(inlineError, label.nextSibling);
									} else { //if there is not a label, add it to the general errors
										let inlineError = document.createElement('li');
										inlineError.innerHTML = validationError;
										errorsContainerEl.appendChild(inlineError);
									}
								});
							}
						}
					})
					.catch(error => {
						alert(error);
					});
			});
		})
	}
}